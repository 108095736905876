////////// SIDEBAR ////////
$sidebar-width-lg: 235px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 65px;

$sidebar-light-bg: #ffffff;
$sidebar-light-menu-color: theme-color(primary);
$sidebar-light-submenu-color: #656565;
$sidebar-light-menu-active-bg: theme-color(primary);
$sidebar-light-menu-active-color: $white;
$sidebar-light-menu-hover-bg: theme-color(primary);
$sidebar-light-menu-hover-color: $white;
$sidebar-light-submenu-hover-color: $black;
$sidebar-light-submenu-active-color: $black;
$sidebar-light-menu-icon-color: theme-color(primary);
$sidebar-light-menu-arrow-color: #686868;

$sidebar-dark-bg: theme-color(primary);
$sidebar-dark-menu-color: #c2acc9;
$sidebar-dark-menu-active-bg: darken($sidebar-dark-bg, 4%);
$sidebar-dark-menu-active-color: $white;
$sidebar-dark-menu-hover-bg: darken($sidebar-dark-bg, 4%);
$sidebar-dark-menu-hover-color: #d0cfcf;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: $white;
$sidebar-dark-submenu-active-color: $white;
$sidebar-dark-menu-icon-color: $sidebar-dark-menu-color;
$sidebar-dark-menu-arrow-color: $sidebar-dark-menu-color;

$sidebar-menu-font-size: .875rem;
$sidebar-menu-padding-left: .75rem;
$sidebar-menu-padding-right: .75rem;
$sidebar-menu-padding-top: .75rem;
$sidebar-menu-padding-bottom: .75rem;

$sidebar-submenu-padding: .25rem 0 0 3.75rem;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding: 1rem 1rem;

$sidebar-icon-font-size:  1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$sidebar-box-shadow: none;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-menu-active-bg;
$icon-only-menu-bg-dark: $sidebar-dark-menu-active-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: $white;
$navbar-height: 70px;
$navbar-menu-color: theme-color(primary);
$navbar-font-size: 1rem;
$navbar-icon-font-size: .9375rem;
$navbar-box-shadow: 0 13px 27px 0 rgba(0, 0, 0, 0.1);

///////// NAVBAR ////////