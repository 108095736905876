/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      background: $table-header-bg;
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 500;
      font-size: .875rem;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    line-height: 1;
  }
  tbody {
    td {
      font-size: $default-font-size;
      img {
        width: 36px;
        height: 36px;
        border-radius: 100%;
      }
      .badge {
        margin-bottom: 0;
      }
    }
    tr {
      &:first-child {
        border-top: 0;
      }
    }
  }
  
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
}
