/* Navbar */

.navbar {
  font-weight: 400;
  transition:background $action-transition-duration $action-transition-timing-function;
  -webkit-transition:background $action-transition-duration $action-transition-timing-function;
  -moz-transition:background $action-transition-duration $action-transition-timing-function;
  -ms-transition:background $action-transition-duration $action-transition-timing-function;
  box-shadow: $navbar-box-shadow;
  -webkit-box-shadow: $navbar-box-shadow;
  -moz-box-shadow: $navbar-box-shadow;
  background: $navbar-default-bg;

  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    .navbar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      margin-right: 0;
      padding: .25rem 0;
      
      &.brand-logo-mini {
        display: none;
      }

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle;
      }
    }

    .brand-logo-mini {
      padding-left: 0;
      text-align: center;
      img {
        width: calc(#{$sidebar-width-icon} - 30px );
        max-width: 100%;
        margin: auto;
      }
    }
  }

  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    width: calc(100%);
    height: $navbar-height;
    @media (max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .nav-toggler-item {
      .navbar-toggler {
        border: 0;
        color: inherit;
        font-size: 1.5rem;
        padding: 0;
      }
      &.nav-toggler-item-right {
        .navbar-toggler {
          @media (max-width:991px) {
            padding-left: 15px;
            padding-right: 11px;
          }
        }
      }
    }

    .navbar-nav {
      @extend .flex-row;
      @extend .align-items-stretch;
      .nav-item {
        border-right: 1px solid $border-color;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        @include display-flex();
        @extend .align-self-stretch;
        @extend .align-items-center;
        &:last-child {
          margin-right: 0;
          border-right: 0;
        }
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
        }
        &.nav-search {
          .input-group {
            border: 1px solid $border-color;
            border-radius: 50px;
            background: transparent;
            .form-control,
            .input-group-text {
              background: transparent;
              border: 0;
              color: $navbar-menu-color;
              padding-top: .75rem;
              padding-bottom: .75rem;
            }
            .form-control {
              padding-left: .5rem;
              width: 220px;
              @include placeholder {
                color: $navbar-menu-color;
              }
            }
            .input-group-text {
              padding-right: 0;
            }
          }
        }
        &.nav-settings {
          margin: 0;
          padding-left: 1.5rem;
          .nav-link {
            padding: 0;
            text-align: center;
          }
          i {
            font-size: 1.5rem;
            vertical-align: middle;
          }
        }
        &.nav-profile {
          .nav-link {
            &:after {
              color: $black;
            }
          }
          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
          }
          .nav-profile-name {
            color: $black;
            font-weight: 300;
            margin-left: .5rem;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
        &.dropdown {
          .dropdown-menu {
            @extend .dropdownAnimation;        
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
          }
          .navbar-dropdown {
            position: absolute;            
            font-size: 0.9rem;
            margin-top: 0;
            right: 0;
            left: auto;
            top: 48px;
            .rtl & {
              right: auto;
              left: 0;
            }
            padding: 0;

            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              padding: 11px 13px;

              i {
                font-size: 17px;
              }

              .badge {
                margin-left: 2.5rem;
              }
              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .rtl & {
                i {
                  @extend .mr-0;
                  margin-left: 10px;
                }
                .badge {
                  margin-left: 0;
                  margin-right: 2.5rem;
                }
              }
            }

            .dropdown-divider {
              margin: 0;
            }
          }
          i {
            margin-right: .5rem;
            vertical-align: middle;
          }
          @media (max-width: 991px) {
            position: static;
            padding-left: 1rem;
            padding-right: 1rem;
            .navbar-dropdown {
              left: 20px;
              right: 20px;
              top: $navbar-height;
              width: calc(100% - 40px);
            }
          }
          .count-indicator {
            position: relative;
            padding: 0;
            text-align: center;
            i {
              font-size: 1.25rem;
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 35%;
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background: theme-color(danger);
              top: -7px;
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  /* Navbar color variations */
  @each $color, $value in $theme-colors {
    &.navbar-#{$color} {
      background: $value;      
      &:not(.navbar-light) {
        .navbar-menu-wrapper {
          .navbar-toggler {
            color: $white;
          }
          .navbar-nav {
            .nav-item {
              border-right-color: rgba($white, .2);
              .nav-link {
                color: $white;
              }
              &.nav-profile {
                .nav-profile-name {
                  color: $white;
                }
                .nav-link {
                  &:after {
                    color: rgba($white, .5);
                  }
                }
              }
              &.nav-search {
                .input-group {
                  border-color: rgba($white, .2);
                  .form-control,
                  .input-group-text {
                    color: $white;
                  }
                  .form-control {
                    @include placeholder {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.navbar-light {
      background: $white;
      .navbar-menu-wrapper {
        .navbar-toggler {
          color: theme-color(dark);
        }
        .nav-item {
          .nav-link {
            color: theme-color(dark);
          }
        }
      }
    }
    &.navbar-dark {
      .navbar-menu-wrapper {
        border-left-color: darken($border-color, 50%);
      }
    }
    &.navbar-primary {
      .navbar-menu-wrapper {
        .nav-item {
          .nav-link {
            &.count-indicator {
              .count {
                background: theme-color(danger);
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;
    .navbar-brand-wrapper {
      width: 55px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
}